import DropDownArrow from "../Asset/Icon/dropDown.png";
import buildingInspection from "../Asset/Img/buildingInspection.png";
import engConsultant from "../Asset/Img/engConsultant.png";
// import interior from "../Asset/Img/interior.png";
import planService from "../Asset/Img/planService.png";
import brand1 from "../Asset/brand/brand1.jpeg";
import brand2 from "../Asset/brand/brand2.png";
import brand3 from "../Asset/brand/brand3.jpeg";
import brand4 from "../Asset/brand/brand4.png";
import brand5 from "../Asset/brand/brand5.png";
import brand6 from "../Asset/brand/brand6.png";

import leader1 from "../Asset/Img/alaa_pic.jpg";
import leader2 from "../Asset/Img/rawan_pic.jpg";
import leader3 from "../Asset/Img/daher_pic.jpg";
import leader4 from "../Asset/Img/jabarin_pic.jpg";
import leader5 from "../Asset/Img/uri_pic.jpg";

import leader1Bg from "../Asset/Img/meetLeader3.png";
import leader2Bg from "../Asset/Img/leader2Bg.png";
import leader3Bg from "../Asset/Img/leader3Bg.png";
import signature1_1 from "../Asset/Img/signature1.1.png";
import signature1_2 from "../Asset/Img/signature1.2.png";
import signature2_1 from "../Asset/Img/signature2.png";
// import signature2_2 from "../Asset/Img/signature2.2.png";
import signature3 from "../Asset/Img/signature3.png";
import project1_1 from "../Asset/project/project1_1.jpg";
import project1_2 from "../Asset/project/project1_2.jpg";
import project1_3 from "../Asset/project/project1_3.jpg";
import project1_4 from "../Asset/project/project1_4.jpg";
import project1_5 from "../Asset/project/project1_5.jpg";
import project2_1 from "../Asset/project/project2_1.jpg";
import project2_2 from "../Asset/project/project2_2.jpg";
import project2_3 from "../Asset/project/project2_3.jpg";
import project2_4 from "../Asset/project/project2_4.jpg";
import project2_5 from "../Asset/project/project2_5.jpg";
import project3_1 from "../Asset/project/project3_1.jpg";
import project3_2 from "../Asset/project/project3_2.jpg";
import project3_3 from "../Asset/project/project3_3.jpg";
import project3_4 from "../Asset/project/project3_4.jpg";
import project3_5 from "../Asset/project/project3_5.jpg";
import project4_1 from "../Asset/project/project4_1.jpg";
import project4_2 from "../Asset/project/project4_2.jpg";
import project4_3 from "../Asset/project/project4_3.jpg";
import project4_4 from "../Asset/project/project4_4.jpg";
import project4_5 from "../Asset/project/project4_5.jpg";
import project4_6 from "../Asset/project/project4_6.jpg";
import project5_1 from "../Asset/project/project5_1.jpg";
import project5_2 from "../Asset/project/project5_2.jpg";
import project5_3 from "../Asset/project/project5_3.jpg";
import project5_4 from "../Asset/project/project5_4.jpg";
import project5_5 from "../Asset/project/project5_5.jpg";
import project6_1 from "../Asset/project/project6_1.jpg";
import project6_2 from "../Asset/project/project6_2.jpg";
import project6_3 from "../Asset/project/project6_3.jpg";
import project6_4 from "../Asset/project/project6_4.jpg";
import project7_1 from "../Asset/project/project7_1.jpg";
import project7_2 from "../Asset/project/project7_2.jpg";
import project7_3 from "../Asset/project/project7_3.jpg";
import project8_1 from "../Asset/project/project8_1.jpg";
import project8_2 from "../Asset/project/project8_2.jpg";
import project9_1 from "../Asset/project/project9_1.jpg";
import project9_2 from "../Asset/project/project9_2.jpg";
import project10_1 from "../Asset/project/project10_1.jpg";
import project10_2 from "../Asset/project/project10_2.jpg";
import project10_3 from "../Asset/project/project10_3.jpg";
import project11_1 from "../Asset/project/project11_1.jpg";
import project11_2 from "../Asset/project/project11_2.jpg";
import project11_3 from "../Asset/project/project11_3.jpg";
import project11_4 from "../Asset/project/project11_4.jpg";
import project12_1 from "../Asset/project/project12_1.jpg";
import project12_2 from "../Asset/project/project12_2.jpg";
import project13_1 from "../Asset/project/project13_1.jpg";
import project13_2 from "../Asset/project/project13_2.jpg";
import project13_3 from "../Asset/project/project13_3.jpg";
import project13_4 from "../Asset/project/project13_4.jpg";
import project14_1 from "../Asset/project/project14_1.jpg";
import project14_2 from "../Asset/project/project14_2.jpg";
import project14_3 from "../Asset/project/project14_3.jpg";
import project14_4 from "../Asset/project/project14_4.jpg";
import project14_5 from "../Asset/project/project14_5.jpg";



import Project1 from "../Asset/Img/project1.png";
import Project2 from "../Asset/Img/project2.png";
import Project3 from "../Asset/Img/project3.png";
import Project4 from "../Asset/Img/project4.png";
import Project5 from "../Asset/Img/project5.png";
import Project6 from "../Asset/Img/project6.png";
import Project7 from "../Asset/Img/project7.png";
import Project8 from "../Asset/Img/project8.png";
import Project9 from "../Asset/Img/project9.png";
import Project10 from "../Asset/Img/project10.png";
import Project11 from "../Asset/Img/project11.png";
import Project12 from "../Asset/Img/project12.png";
import Project13 from "../Asset/Img/project13.png";
import Project14 from "../Asset/Img/project14.png";

import client1 from "../Asset/Img/client1.png";
import client2 from "../Asset/Img/client2.png";
import client3 from "../Asset/Img/client3.png";
import client4 from "../Asset/Img/client4.png";
import client5 from "../Asset/Img/client5.png";
import client6 from "../Asset/Img/client6.png";
import leaderOneBackground from "../Asset/Img/mobileTeamone.png";
import leaderTwoBackground from "../Asset/Img/mobileTeamtwo.png";
import leaderThreeBackground from "../Asset/Img/mobileteamthree.png";

export const pageRoutesEng = [
  {
    id: 1,
    label: "home",
    path: "/",
    icon: null,
  },
  {
    id: 2,
    label: "who we are",
    path: "/whoWeAre",
    icon: DropDownArrow,
  },
  {
    id: 3,
    label: "what we do",
    path: "/whatWeDO",
    icon: null,
  },
  {
    id: 4,
    label: "portfolio",
    path: "/protfolio",
    icon: null,
  },
  {
    id: 5,
    label: "what our client say",
    path: "/whatOurClientsSay",
    icon: null,
  },
  {
    id: 6,
    label: "get in touch",
    path: "/getInTouch",
    icon: null,
  },
];

export const pageRoutesHebrew = [
  {
    id: 6,
    label: "צור קשר",
    path: "/getInTouch",
    icon: null,
  },
  {
    id: 5,
    label: "ממליצים עלינו",
    path: "/whatOurClientsSay",
    icon: null,
  },
  {
    id: 4,
    label: "פורטפוליו",
    path: "/protfolio",
    icon: null,
  },
  {
    id: 3,
    label: "מה אנו עושים",
    path: "/whatWeDO",
    icon: null,
  },
  {
    id: 2,
    label: "מי אנחנו",
    path: "/whoWeAre",
    icon: DropDownArrow,
  },
  {
    id: 1,
    label: "בית",
    path: "/",
    icon: null,
  }
];
export const adminRoutes = [
  { id: 1, label: "Product", path: "/admin/addProduct", icon: null },
  { id: 2, label: "Contact Info", path: "/admin/contactInfo", icon: null },
  { id: 3, label: "Inquery", path: "/admin/Inquery", icon: null },
];

export const serviceDataEng = [
  {
    id: 1,
    icon: planService,
    label: "PLANNING SERVICES",
  },
  {
    id: 2,
    icon: buildingInspection,
    label: "APPRAISAL SERVICES",
  },
  // {
  //   id: 3,
  //   icon: interior,
  //   label: "INTERIOR DESIGN",
  // },
  {
    id: 4,
    icon: engConsultant,
    label: "ENGINEERING CONSULTATION",
  },
];

export const serviceDataHebrew = [
  {
    id: 1,
    icon: planService,
    label: "שירותי תכנון",
  },
  {
    id: 2,
    icon: buildingInspection,
    label: "שירותי שמאות מקרקעין",
  },
  // {
  //   id: 3,
  //   icon: interior,
  //   label: "עיצוב פנים",
  // },
  {
    id: 4,
    icon: engConsultant,
    label: "ייעוץ הנדסי",
  },
];

export const brandData = [
  {
    id: 1,
    img: brand1,
  },
  {
    id: 2,
    img: brand2,
  },
  {
    id: 3,
    img: brand3,
  },
  {
    id: 4,
    img: brand4,
  },
  {
    id: 5,
    img: brand5,
  },
  {
    id: 6,
    img: brand6,
  },
];
export const leaderHebrewData = [
  {
    id: 1,
    name: "עלא בשארה"
  },
  {
    id: 2,
    name: "רואן בשארה סבאח"
  },
  {
    id: 3,
    name: "מוחמד דאהר"
  },
  {
    id: 4,
    name: "אורי רוזין"
  },
  {
    id: 5,
    name: "מחמוד ג'בארין"
  },
]
export const leaderData = [
  {
    id: 1,
    name: "Alaa Bishara",
    designation: "מנהל מחלקת פיתוח",
    detailsText: [
      `עלאא בשארה, מנהל מחלקת פיתוח עסקי ב-איי.ביי הנדס`,
      `על רקע לימודי מגוון הכולל תואר ראשון במשפטים ומנהל עסקים מאוניברסיטת רייכמן ותואר שני במנהל עסקים בהתמחות בנדל"ן ושמאות, עלאא מביא לצוות שילוב מיומנויות ייחודי.`,
      ` כמייסד סדרתי, יזם וסמנכ"ל מכירות, הוא שיחק תפקידים מרכזיים במספר חברות מצליחות. מנהיגותו בעלת החזון וניסיונו הרב תורמים באופן משמעותי לצמיחתה והצלחתה של איי.ביי הנדסה"`,
    ],
    img: leader1,
    backgroundImg: leader1Bg,
    backgroundImg2: leaderOneBackground,
    signature: `${(
      <div className="dflex_center">
        <img src={signature1_1} alt="sig1" />
        <img src={signature1_2} alt="sig1" />
      </div>
    )}`,
    linkedIn: "https://www.linkedin.com/in/alaa-bishara-9ba97840/",
  },
  {
    id: 2,
    name: "Rawan Bishara Sabbah",
    designation: "ראש מחלקת עיצוב פנים",
    detailsText: [
      "As the dedicated leader of our Interior Design Department, Rawan Beshara Sabah brings a wealth of expertise to IB Engineering.",
      "A distinguished alumna of the Technion with a degree in civil engineering, specialized in structures, and honored in interior design, Rawan has been a registered engineer and architect since 2009.",
      "With over 15 years of experience, she has been the guiding force behind numerous construction and interior design projects, particularly in residential towers across central and northern regions.",
      "Rawan's passion for art, fashion, and a discerning taste infuse her work with creativity and innovation, elevating every project to new heights of excellence.",
    ],
    backgroundImg: leader2Bg,
    backgroundImg2: leaderTwoBackground,
    img: leader2,
    signature: `${(
      <div className="">
        <img src={signature2_1} alt="sig1" />
      </div>
    )}`,
    linkedIn: "",
  },
  {
    id: 3,
    name: "Mohammad Daher",
    designation: "ראש מחלקת תכנון",
    detailsText: [
      "Is a rising star at IB ENG. Started as an intern, Today he is a licensed engineer, he honed his skills under our founder's guidance.",
      "His youthful energy and extensive experience drive our planning department's commitment to excellence, ensuring that each project is meticulously planned and executed with precision.",
      "Mohammad's visionary leadership and collaborative. Approach inspire our team to achieve new heights in the world of construction planning.",
    ],
    backgroundImg: leader3Bg,
    backgroundImg2: leaderThreeBackground,
    img: leader3,
    signature: `${(
      <div className="">
        <img src={signature3} alt="sig1" />
        {/* <img src={signature2_2} alt="sig1" /> */}
      </div>
    )}`,
    linkedIn: "",
  },
  {
    id: 4,
    name: "Uri Rozin",
    designation: "ראש מחלקת תכנון",
    detailsText: [
      "With over 20 years of experience in the field, Uri Rozin is a seasoned property appraiser and holds a bachelor's degree in Social Sciences from the Open University. In the past decade, he has managed and initiated numerous urban renewal projects for leading development companies, including Ashdar Building Company from Ashtrom Group, Minrav Projects, Natif Group, and most recently as VP of Business Development at Sufrin Group.",
    ],
    backgroundImg: leader3Bg,
    backgroundImg2: leaderThreeBackground,
    img: leader5,
    signature: `${(
      <div className="">
        <img src={signature3} alt="sig1" />
        {/* <img src={signature2_2} alt="sig1" /> */}
      </div>
    )}`,
    linkedIn: "https://www.linkedin.com/in/uri-rozin-%D7%90%D7%95%D7%A8%D7%99-%D7%A8%D7%95%D7%96%D7%99%D7%9F/",
  },
  {
    id: 5,
    name: "Mahmoud Jabarin",
    designation: "Intern Engineer",
    detailsText: [
      "Mahmoud Jabarin represents the new blood of our company, currently completing his internship under the guidance of IBE's expert team. At IBE, we believe that investing in our interns is essential because they are the future of our company. Mahmoud has already demonstrated his potential with creative solutions and innovative thinking. This promising young engineer has an impressive future ahead of him, and we're excited to see where his career will take him.",
    ],
    backgroundImg: leader2Bg,
    backgroundImg2: leaderTwoBackground,
    img: leader4,
    signature: `${(
      <div className="">
        <img src={signature2_1} alt="sig1" />
        {/* <img src={signature2_2} alt="sig1" /> */}
      </div>
    )}`,
    linkedIn: "",
  },
  
];
export const projectDataHebrew = [
  {
    id: 1,
    projectName: "בניין חווא, נצרת",
    mainProject: Project1,
    projectDetails: [
      "בניין משרדים הכולל 8 קומות של שטחי מסחר ומשרדים, וכן קומת חניה.",
      `הבניין משתרע על שטח של כ-4,000 מ&quot;ר. <br/><span style='font-weight: 700;'>היזם:</span>ח.ע יפיע למסחר והשקעות`,
    ],
    imgBackground: [
      {
        background:
          "linear-gradient(180deg, #1553B1 0%, rgba(70, 125, 193, 0.60) 89.23%, rgba(99, 150, 202, 0.00) 165.59%)",
        img: project1_1,
      },
      {
        background:
          "linear-gradient(180deg, #176EB9 0%, rgba(70, 125, 193, 0.60) 89.23%, rgba(99, 150, 202, 0.00) 165.59%)",
        img: project1_2,
      },
      {
        background:
          "linear-gradient(180deg, #66AFF7 0%, rgba(28, 90, 153, 0.00) 169.67%, rgba(70, 125, 193, 0.60) 182.33%)",
        img: project1_3,
      },
      {
        background:
          "linear-gradient(180deg, #6395BA 0%, rgba(99, 150, 202, 0.00) 169.67%, rgba(70, 125, 193, 0.60) 183.85%)",
        img: project1_4,
      },
      {
        background:
          "linear-gradient(180deg, #A38BAC 0%, rgba(99, 150, 202, 0.00) 169.67%, rgba(70, 125, 193, 0.60) 183.85%)",
        img: project1_5,
      },
    ],
  },
  {
    id: 2,
    projectName: "זידאן",
    mainProject: Project2,
    projectDetails: [
      "זידאן 2, נצרת. בניין מגורים בן 10 קומות עם שתי קומות חניה תת-קרקעיות.",
      `הפרויקט כולל 38 יחידות דיור עם דירות בנות 4 ו-5 חדרים ופנטהאוזים.<br/><span style='font-weight: 700;'>היזם:</span>תופיק זידאן בע״מ<br/><span style='font-weight: 700;'>אדריכל:</span>חסן אדריכלים`,
    ],
    imgBackground: [
      {
        background:
          "linear-gradient(180deg, #5986B0 0%, rgba(70, 125, 193, 0.60) 89.23%, rgba(99, 150, 202, 0.00) 165.59%)",
        img: project2_1,
      },
      {
        background:
          "linear-gradient(180deg, #9BC3E4 0%, rgba(62, 95, 128, 0.00) 160.69%, rgba(70, 125, 193, 0.60) 165.59%)",
        img: project2_2,
      },
      {
        background:
          "linear-gradient(180deg, #9CC6E5 0%, rgba(70, 125, 193, 0.60) 165.59%, rgba(99, 150, 202, 0.00) 165.59%)",
        img: project2_3,
      },
      {
        background:
          "linear-gradient(180deg, #8B9086 0%, rgba(129, 142, 117, 0.00) 214.38%, rgba(70, 125, 193, 0.60) 227.18%)",
        img: project2_4,
      },
      {
        background:
          "linear-gradient(180deg, #A5D3FF 0%, rgba(0, 62, 119, 0.00) 150.74%, rgba(70, 125, 193, 0.60) 159.74%)",
        img: project2_5,
      },
    ],
  },
  {
    id: 3,
    projectName: "פנורמה אלבשארה",
    mainProject: Project3,
    projectDetails: [
      "בניין אלבשארה, נצרת. בניין מגורים ומסחר בן 8 קומות עם מבנה חניה בן 4 קומות.",
      `הפרויקט כולל 26 יחידות דיור עם דירות בנות 4 ו-5 חדרים ופנטהאוזים.<br/><span style='font-weight: 700;'>היזם:</span>נאסר איברהים נעמה`,
    ],
    imgBackground: [
      {
        background:
          "linear-gradient(180deg, #54789C 0%, #F3EFEA 200.4%, rgba(70, 125, 193, 0.60) 202.62%)",
        img: project3_1,
      },
      {
        background:
          "linear-gradient(180deg, #306D9C 0%, #F3EFEA 198.91%, #8AA2BA 202.62%)",
        img: project3_2,
      },
      {
        background:
          "linear-gradient(180deg, #656B8F 0%, #E5EFE7 198.18%, #F3EFEA 202.62%)",
        img: project3_2,
      },
      {
        background:
          "linear-gradient(180deg, #4074A3 0%, #E5EFE7 198.18%, #F3EFEA 202.62%)",
        img: project3_2,
      },
      {
        background:
          "linear-gradient(180deg, #4C88BC 0%, #E3E6EB 198.18%, #F3EFEA 202.62%)",
        img: project3_5,
      },
    ],
  },
  {
    id: 4,
    projectName: "מרכז בסמת טבעון",
    mainProject: Project4,
    projectDetails: [
      "מרכז מסחרי ומשרדים בלב טבעון, המשתרע על כ-3,000 מ&quot;ר.",
      "<br/><span style='font-weight: 700;'>היזם:</span>תוראב השקעות ופיתוח<br/><span style='font-weight: 700;'>אדריכל:</span>ערן מיבל",
    ],
    imgBackground: [
      {
        background:
          "linear-gradient(180deg, #83A6CC 0%, rgba(70, 125, 193, 0.50) 195.09%, #F3EFEA 200.4%)",
        img: project4_1,
      },
      {
        background:
          "linear-gradient(180deg, #00294F 0%, rgba(70, 125, 193, 0.50) 193.28%, #F0F0F2 202.25%)",
        img: project4_2,
      },
      {
        background:
          "linear-gradient(180deg, #454A68 0%, #B9A8C0 193.28%, #F0F0F2 202.25%)",
        img: project4_3,
      },
      {
        background:
          "linear-gradient(180deg, #1D8ACF 0%, #B9A8C0 193.28%, #F0F0F2 202.25%)",
        img: project4_4,
      },
      {
        background:
          "linear-gradient(180deg, #6086AD 0%, #82A9D2 193.28%, #F0F0F2 202.25%)",
        img: project4_5,
      },
      {
        background:
          "linear-gradient(180deg, #216B9A 0%, #82B1DD 193.28%, #F0F0F2 202.25%)",
        img: project4_6,
      },
    ],
  },
  {
    id: 5,
    projectName: "אחוזת הברון, זכרון יעקב",
    mainProject: Project5,
    projectDetails: [
      "מגדל מגורים באחוזת הברון, בן 8 קומות",
      `הבניין כולל 55 יחידות דיור עם דירות בנות 4 ו-5 חדרים ופנטהאוזים.<br/><span style='font-weight: 700;'>היזם:</span>צמרות בע&quot;מ`
      ],
      imgBackground: [
      { background:
          "linear-gradient(180deg, #B5E0FF 0%, #B0C1DD 195.09%, #F3EFEA 200.4%)",
        img: project5_1,
      },
      {
        background:
          "linear-gradient(180deg, #578BBA 0%, rgba(176, 193, 221, 0.60) 181.46%, #F3EFEA 200.4%)",
        img: project5_2,
      },
      {
        background:
          "linear-gradient(180deg, #A5C8FA 0%, #C0C6E2 181.46%, #F3EFEA 200.4%)",
        img: project5_3,
      },
      {
        background:
          "linear-gradient(180deg, #D7C6C6 0%, #D5E5F2 181.46%, #F3EFEA 200.4%)",
        img: project5_4,
      },
      {
        background:
          "linear-gradient(180deg, #DFD1D1 0%, #8C9DBC 97.03%, #F3EFEA 200.4%)",
        img: project5_5,
      },
    ],
  },
  {
    id: 6,
    projectName: "מגורים שערי טוביה ",
    mainProject: Project6,
    projectDetails: [
      "בנייני מגורים שערי טוביה בהר יונה. הבניינים בני 9 קומות",
      "הפרויקט כולל כ-25,000 מ&quot;ר ו-190 יחידות דיור עם דירות בנות 4 ו-5 חדרים ופנטהאוזים.<br/><span style='font-weight: 700;'>היזם:</span>ע.ע אבו ראס<br/><span style='font-weight: 700;'>אדריכל:</span>גליל אדריכלים",
    ],
    imgBackground: [
      {
        background:
          "linear-gradient(180deg, #B5E0FF 0%, #B0C1DD 195.09%, #F3EFEA 200.4%)",
        img: project6_1,
      },
      {
        background:
          "linear-gradient(180deg, #578BBA 0%, rgba(176, 193, 221, 0.60) 181.46%, #F3EFEA 200.4%)",
        img: project6_2,
      },
      {
        background:
          "linear-gradient(180deg, #A5C8FA 0%, #C0C6E2 181.46%, #F3EFEA 200.4%)",
        img: project6_3,
      },
      {
        background:
          "linear-gradient(180deg, #D7C6C6 0%, #D5E5F2 181.46%, #F3EFEA 200.4%)",
        img: project6_4,
      }
    ],
  },
  {
    id: 7,
    projectName: "קדימה 43 ",
    mainProject: Project7,
    projectDetails: [
      "קדימה 43, חיפה. בניין מגורים בן 8 קומות עם שתי קומות חניה תת-קרקעיות",
      "הפרויקט כולל 21 יחידות דיור עם דירות בנות 4 ו-5 חדרים ופנטהאוזים.<br/><span style='font-weight: 700;'>היזם:</span>צמרות<br/><span style='font-weight: 700;'>אדריכל:</span>מאייר אדריכלים",
    ],
    imgBackground: [
      {
        background:
          "linear-gradient(180deg, #B5E0FF 0%, #B0C1DD 195.09%, #F3EFEA 200.4%)",
        img: project7_1,
      },
      {
        background:
          "linear-gradient(180deg, #578BBA 0%, rgba(176, 193, 221, 0.60) 181.46%, #F3EFEA 200.4%)",
        img: project7_2,
      },
      {
        background:
          "linear-gradient(180deg, #A5C8FA 0%, #C0C6E2 181.46%, #F3EFEA 200.4%)",
        img: project7_3,
      }
    ],
  },
  {
    id: 8,
    projectName: "לבונה 30, חיפה ",
    mainProject: Project8,
    projectDetails: [
      "לבונה 30, חיפה. שני בנייני מגורים בני 12 קומות, קומת חניה וקומת מחסנים.",
      "הפרויקט כולל סך הכל 53 יחידות דיור.<br/><span style='font-weight: 700;'>היזם:</span>צמרות<br/><span style='font-weight: 700;'>אדריכל:</span>מאייר אדריכלים",
    ],
    imgBackground: [
      {
        background:
          "linear-gradient(180deg, #B5E0FF 0%, #B0C1DD 195.09%, #F3EFEA 200.4%)",
        img: project8_1,
      },
      {
        background:
          "linear-gradient(180deg, #578BBA 0%, rgba(176, 193, 221, 0.60) 181.46%, #F3EFEA 200.4%)",
        img: project8_2,
      },
    ],
  },
  {
    id: 9,
    projectName: "מרכז רב-תכליתי",
    mainProject: Project9,
    projectDetails: [
      "מרכז רב-תכליתי, באקה.",
      "מבנה ציבורי המיועד לפעילויות שונות עבור תושבי באקה.<br/><span style='font-weight: 700;'>אדריכל:</span>מאמון חסן",
    ],
    imgBackground: [
      {
        background:
          "linear-gradient(180deg, #B5E0FF 0%, #B0C1DD 195.09%, #F3EFEA 200.4%)",
        img: project9_1,
      },
      {
        background:
          "linear-gradient(180deg, #578BBA 0%, rgba(176, 193, 221, 0.60) 181.46%, #F3EFEA 200.4%)",
        img: project9_2,
      },
    ],
  },
  {
    id: 10,
    projectName: "מרכז עוצמה",
    mainProject: Project10,
    projectDetails: [
      "מרכז עוצמה, טורעאן.",
      "מרכז קהילתי המספק שירותים חברתיים ופעילויות קהילתיות לתושבי טורעאן.<br/><span style='font-weight: 700;'>היזם:</span>מועצת טורעאן<br/><span style='font-weight: 700;'>אדריכל:</span>מאמון חסן",
    ],
    imgBackground: [
      {
        background:
          "linear-gradient(180deg, #B5E0FF 0%, #B0C1DD 195.09%, #F3EFEA 200.4%)",
        img: project10_1,
      },
      {
        background:
          "linear-gradient(180deg, #578BBA 0%, rgba(176, 193, 221, 0.60) 181.46%, #F3EFEA 200.4%)",
        img: project10_2,
      },
      {
        background:
          "linear-gradient(180deg, #A5C8FA 0%, #C0C6E2 181.46%, #F3EFEA 200.4%)",
        img: project10_3,
      }
    ],
  },
  {
    id: 11,
    projectName: "מחלקת הרווחה , נשר ",
    mainProject: Project11,
    projectDetails: [
      "מחלקת הרווחה של עיריית נשר, נשר. בניין דו-קומתי מעל קומת חניה.",
      "<span style='font-weight: 700;'>היזם:</span>עיריית נשר<br/><span style='font-weight: 700;'>אדריכל:</span>עירן מיבל",
    ],
    imgBackground: [
      {
        background:
          "linear-gradient(180deg, #B5E0FF 0%, #B0C1DD 195.09%, #F3EFEA 200.4%)",
        img: project11_1,
      },
      {
        background:
          "linear-gradient(180deg, #578BBA 0%, rgba(176, 193, 221, 0.60) 181.46%, #F3EFEA 200.4%)",
        img: project11_2,
      },
      {
        background:
          "linear-gradient(180deg, #A5C8FA 0%, #C0C6E2 181.46%, #F3EFEA 200.4%)",
        img: project11_3,
      },
      {
        background:
          "linear-gradient(180deg, #D7C6C6 0%, #D5E5F2 181.46%, #F3EFEA 200.4%)",
        img: project11_4,
      }
    ],
  },
  {
    id: 12,
    projectName: " זידאון טאוור",
    mainProject: Project12,
    projectDetails: [
      "בנייני מגורים זידאון טאוור, נצרת. שני בניינים בני 10 קומות כל אחד, הכוללים שתי קומות חניה תת-קרקעיות.",
      "הפרויקט כולל 56 יחידות דיור עם דירות בנות 4 ו-5 חדרים ופנטהאוזים.<br/><span style='font-weight: 700;'>היזם:</span>תופיק זידאן בע״מ<br/><span style='font-weight: 700;'>אדריכל:</span>מאמון חסן",
    ],
    imgBackground: [
      {
        background:
          "linear-gradient(180deg, #B5E0FF 0%, #B0C1DD 195.09%, #F3EFEA 200.4%)",
        img: project12_1,
      },
      {
        background:
          "linear-gradient(180deg, #578BBA 0%, rgba(176, 193, 221, 0.60) 181.46%, #F3EFEA 200.4%)",
        img: project12_2,
      }
    ],
  },
  {
    id: 13,
    projectName: "מועדון נוער ערערה",
    mainProject: Project13,
    projectDetails: [
      "מועדון נוער ערערה.",
      "מבנה ציבורי המיועד לפעילויות פנאי, חינוך וחברה עבור בני הנוער בערערה.<br/><span style='font-weight: 700;'>היזם:</span>מועצת ערערה<br/><span style='font-weight: 700;'>אדריכל:</span>מאמון חסן",
    ],
    imgBackground: [
      {
        background:
          "linear-gradient(180deg, #B5E0FF 0%, #B0C1DD 195.09%, #F3EFEA 200.4%)",
        img: project13_1,
      },
      {
        background:
          "linear-gradient(180deg, #578BBA 0%, rgba(176, 193, 221, 0.60) 181.46%, #F3EFEA 200.4%)",
        img: project13_2,
      },
      {
        background:
          "linear-gradient(180deg, #A5C8FA 0%, #C0C6E2 181.46%, #F3EFEA 200.4%)",
        img: project13_3,
      },
      {
        background:
          "linear-gradient(180deg, #D7C6C6 0%, #D5E5F2 181.46%, #F3EFEA 200.4%)",
        img: project13_4,
      }
    ],
  },
  {
    id: 14,
    projectName: "קטאין אל שומר",
    mainProject: Project14,
    projectDetails: [
      "קטאין אל שומר, בית ספר יסודי באום אל פחם. פרויקט בשטח של כ-6,800 מ&quot;ר.",
      "<span style='font-weight: 700;'>היזם:</span>עיריית אום אל פחם<br/><span style='font-weight: 700;'>אדריכל:</span>מאמון חסן",
    ],
    imgBackground: [
      {
        background:
          "linear-gradient(180deg, #B5E0FF 0%, #B0C1DD 195.09%, #F3EFEA 200.4%)",
        img: project14_1,
      },
      {
        background:
          "linear-gradient(180deg, #578BBA 0%, rgba(176, 193, 221, 0.60) 181.46%, #F3EFEA 200.4%)",
        img: project14_2,
      },
      {
        background:
          "linear-gradient(180deg, #A5C8FA 0%, #C0C6E2 181.46%, #F3EFEA 200.4%)",
        img: project14_3,
      },
      {
        background:
          "linear-gradient(180deg, #D7C6C6 0%, #D5E5F2 181.46%, #F3EFEA 200.4%)",
        img: project14_4,
      },
      {
        background:
          "linear-gradient(180deg, #4C88BC 0%, #E3E6EB 198.18%, #F3EFEA 202.62%)",
        img: project14_5,
      }
    ],
  }
];
export const projectDataEnglish = [
  {
    id: 1,
    projectName: "Hawwa Building, Nazareth",
    mainProject: Project1,
    projectDetails: [
      "An office building with 8 floors of commercial and office space and an additional parking level.",
      "The building spans approximately 4,000 square meters. <br/><span style='font-weight: 700;'>Developer :</span> A.H. Yifei for trade and investments",
    ],
    imgBackground: [
      {
        background:
          "linear-gradient(180deg, #1553B1 0%, rgba(70, 125, 193, 0.60) 89.23%, rgba(99, 150, 202, 0.00) 165.59%)",
        img: project1_1,
      },
      {
        background:
          "linear-gradient(180deg, #176EB9 0%, rgba(70, 125, 193, 0.60) 89.23%, rgba(99, 150, 202, 0.00) 165.59%)",
        img: project1_2,
      },
      {
        background:
          "linear-gradient(180deg, #66AFF7 0%, rgba(28, 90, 153, 0.00) 169.67%, rgba(70, 125, 193, 0.60) 182.33%)",
        img: project1_3,
      },
      {
        background:
          "linear-gradient(180deg, #6395BA 0%, rgba(99, 150, 202, 0.00) 169.67%, rgba(70, 125, 193, 0.60) 183.85%)",
        img: project1_4,
      },
      {
        background:
          "linear-gradient(180deg, #A38BAC 0%, rgba(99, 150, 202, 0.00) 169.67%, rgba(70, 125, 193, 0.60) 183.85%)",
        img: project1_5,
      },
    ],
  },
  {
    id: 2,
    projectName: "Zidan 2",
    mainProject: Project2,
    projectDetails: [
      "Zidan 2, Nazareth. A 10-story residential building with two underground parking levels.",
      "The project includes 38 residential units with 4 and 5-room apartments and penthouses. <br/><span style='font-weight: 700;'>Developer :</span> Tofik Zidan Ltd. <br/><span style='font-weight: 700;'>Architect :</span> Hasan Architects",
    ],
    imgBackground: [
      {
        background:
          "linear-gradient(180deg, #5986B0 0%, rgba(70, 125, 193, 0.60) 89.23%, rgba(99, 150, 202, 0.00) 165.59%)",
        img: project2_1,
      },
      {
        background:
          "linear-gradient(180deg, #9BC3E4 0%, rgba(62, 95, 128, 0.00) 160.69%, rgba(70, 125, 193, 0.60) 165.59%)",
        img: project2_2,
      },
      {
        background:
          "linear-gradient(180deg, #9CC6E5 0%, rgba(70, 125, 193, 0.60) 165.59%, rgba(99, 150, 202, 0.00) 165.59%)",
        img: project2_3,
      },
      {
        background:
          "linear-gradient(180deg, #8B9086 0%, rgba(129, 142, 117, 0.00) 214.38%, rgba(70, 125, 193, 0.60) 227.18%)",
        img: project2_4,
      },
      {
        background:
          "linear-gradient(180deg, #A5D3FF 0%, rgba(0, 62, 119, 0.00) 150.74%, rgba(70, 125, 193, 0.60) 159.74%)",
        img: project2_5,
      },
    ],
  },
  {
    id: 3,
    projectName: "Panorama AlBeshara",
    mainProject: Project3,
    projectDetails: [
      "Albeshara Building, Nazareth. An 8-story residential and commercial building with a 4-story parking structure.",
      "The project includes 26 residential units with 4 and 5-room apartments and penthouses. <br/><span style='font-weight: 700;'>Developer :</span> Nasser Ibrahim Nama.",
    ],
    imgBackground: [
      {
        background:
          "linear-gradient(180deg, #54789C 0%, #F3EFEA 200.4%, rgba(70, 125, 193, 0.60) 202.62%)",
        img: project3_1,
      },
      {
        background:
          "linear-gradient(180deg, #306D9C 0%, #F3EFEA 198.91%, #8AA2BA 202.62%)",
        img: project3_2,
      },
      {
        background:
          "linear-gradient(180deg, #656B8F 0%, #E5EFE7 198.18%, #F3EFEA 202.62%)",
        img: project3_3,
      },
      {
        background:
          "linear-gradient(180deg, #4074A3 0%, #E5EFE7 198.18%, #F3EFEA 202.62%)",
        img: project3_4,
      },
      {
        background:
          "linear-gradient(180deg, #4C88BC 0%, #E3E6EB 198.18%, #F3EFEA 202.62%)",
        img: project3_5,
      },
    ],
  },
  {
    id: 4,
    projectName: "Basmet Tabo'n Center",
    mainProject: Project4,
    projectDetails: [
      "A commercial and office center located in the heart of Tiv&#39;on, spanning approximately 3,000 square meters",
      "<br/><span style='font-weight: 700;'>Developer :</span> Torab Investments and Development. <br/><span style='font-weight: 700;'>Architect :</span> Eran Mebel",
    ],
    imgBackground: [
      {
        background:
          "linear-gradient(180deg, #83A6CC 0%, rgba(70, 125, 193, 0.50) 195.09%, #F3EFEA 200.4%)",
        img: project4_1,
      },
      {
        background:
          "linear-gradient(180deg, #00294F 0%, rgba(70, 125, 193, 0.50) 193.28%, #F0F0F2 202.25%)",
        img: project4_2,
      },
      {
        background:
          "linear-gradient(180deg, #454A68 0%, #B9A8C0 193.28%, #F0F0F2 202.25%)",
        img: project4_3,
      },
      {
        background:
          "linear-gradient(180deg, #1D8ACF 0%, #B9A8C0 193.28%, #F0F0F2 202.25%)",
        img: project4_4,
      },
      {
        background:
          "linear-gradient(180deg, #6086AD 0%, #82A9D2 193.28%, #F0F0F2 202.25%)",
        img: project4_5,
      },
      {
        background:
          "linear-gradient(180deg, #216B9A 0%, #82B1DD 193.28%, #F0F0F2 202.25%)",
        img: project4_6,
      },
    ],
  },
  {
    id: 5,
    projectName: "Ahozat Habaron, Zichron Yaakov.",
    mainProject: Project5,
    projectDetails: [
      "A residential tower in Baron&#39;s Estate with 8 floors.",
      "The building includes 55 residential units with 4 and 5-room apartments and penthouses. <br/><span style='font-weight: 700;'> Developer :</span> Tzamrot Ltd.",
    ],
    imgBackground: [
      {
        background:
          "linear-gradient(180deg, #B5E0FF 0%, #B0C1DD 195.09%, #F3EFEA 200.4%)",
        img: project5_1,
      },
      {
        background:
          "linear-gradient(180deg, #578BBA 0%, rgba(176, 193, 221, 0.60) 181.46%, #F3EFEA 200.4%)",
        img: project5_2,
      },
      {
        background:
          "linear-gradient(180deg, #A5C8FA 0%, #C0C6E2 181.46%, #F3EFEA 200.4%)",
        img: project5_3,
      },
      {
        background:
          "linear-gradient(180deg, #D7C6C6 0%, #D5E5F2 181.46%, #F3EFEA 200.4%)",
        img: project5_4,
      },
      {
        background:
          "linear-gradient(180deg, #DFD1D1 0%, #8C9DBC 97.03%, #F3EFEA 200.4%)",
        img: project5_5,
      },
    ],
  },
  {
    id: 6,
    projectName: "Shaarei Tuvia",
    mainProject: Project6,
    projectDetails: [
      "Shaarei Tuvia residential buildings in Har Yona. The buildings have 9 floors.",
      "The project spans approximately 25,000 square meters and includes 190 residential units with 4 and 5-room apartments and penthouses. <br/><span style='font-weight: 700;'>Developer :</span> A.A. Abu Ras <br/><span style='font-weight: 700;'>Architect :</sapn> Galil Architects",
    ],
    imgBackground: [
      {
        background:
          "linear-gradient(180deg, #B5E0FF 0%, #B0C1DD 195.09%, #F3EFEA 200.4%)",
        img: project6_1,
      },
      {
        background:
          "linear-gradient(180deg, #578BBA 0%, rgba(176, 193, 221, 0.60) 181.46%, #F3EFEA 200.4%)",
        img: project6_2,
      },
      {
        background:
          "linear-gradient(180deg, #A5C8FA 0%, #C0C6E2 181.46%, #F3EFEA 200.4%)",
        img: project6_3,
      },
      {
        background:
          "linear-gradient(180deg, #D7C6C6 0%, #D5E5F2 181.46%, #F3EFEA 200.4%)",
        img: project6_4,
      }
    ],
  },
  {
    id: 7,
    projectName: "Kadima 43",
    mainProject: Project7,
    projectDetails: [
      "Kadima 43, Haifa. An 8-story residential building with two underground parking levels.",
      "The project includes 21 residential units with 4 and 5-room apartments and penthouses. <br/><span style='font-weight: 700;'>Developer :</span> Tzamrot <br/><span style='font-weight: 700;'>Architect :</span> Meir Architects",
    ],
    imgBackground: [
      {
        background:
          "linear-gradient(180deg, #B5E0FF 0%, #B0C1DD 195.09%, #F3EFEA 200.4%)",
        img: project7_1,
      },
      {
        background:
          "linear-gradient(180deg, #578BBA 0%, rgba(176, 193, 221, 0.60) 181.46%, #F3EFEA 200.4%)",
        img: project7_2,
      },
      {
        background:
          "linear-gradient(180deg, #A5C8FA 0%, #C0C6E2 181.46%, #F3EFEA 200.4%)",
        img: project7_3,
      }
    ],
  },
  {
    id: 8,
    projectName: "Levona 30",
    mainProject: Project8,
    projectDetails: [
      "Levona 30, Haifa. Two 12-story residential buildings with a parking level and a storage level.",
      "The project includes a total of 53 residential units.<br/><span style='font-weight: 700;'>Developer: Tzamrot</span> <br/><span style='font-weight: 700;'>Architect :</span> Meir Architects",
    ],
    imgBackground: [
      {
        background:
          "linear-gradient(180deg, #B5E0FF 0%, #B0C1DD 195.09%, #F3EFEA 200.4%)",
        img: project8_1,
      },
      {
        background:
          "linear-gradient(180deg, #578BBA 0%, rgba(176, 193, 221, 0.60) 181.46%, #F3EFEA 200.4%)",
        img: project8_2,
      },
    ],
  },
  {
    id: 9,
    projectName: "Multipurpose Center",
    mainProject: Project9,
    projectDetails: [
      "Multipurpose Center, Baqa",
      "A public facility designed for various activities for the residents of Baqa.",
    ],
    imgBackground: [
      {
        background:
          "linear-gradient(180deg, #B5E0FF 0%, #B0C1DD 195.09%, #F3EFEA 200.4%)",
        img: project9_1,
      },
      {
        background:
          "linear-gradient(180deg, #578BBA 0%, rgba(176, 193, 221, 0.60) 181.46%, #F3EFEA 200.4%)",
        img: project9_2,
      },
    ],
  },
  {
    id: 10,
    projectName: "Strength Center",
    mainProject: Project10,
    projectDetails: [
      "Strength Center, Tur&#39;an",
      "A community center providing social services and community activities for the residents of Tur&#39;an. <br/><span style='font-weight: 700;'>Developer :</span> Tur&#39;an Municipality <br/><span style='font-weight: 700;'>Architect :</span> Mamoun Hasan",
    ],
    imgBackground: [
      {
        background:
          "linear-gradient(180deg, #B5E0FF 0%, #B0C1DD 195.09%, #F3EFEA 200.4%)",
        img: project10_1,
      },
      {
        background:
          "linear-gradient(180deg, #578BBA 0%, rgba(176, 193, 221, 0.60) 181.46%, #F3EFEA 200.4%)",
        img: project10_2,
      },
      {
        background:
          "linear-gradient(180deg, #A5C8FA 0%, #C0C6E2 181.46%, #F3EFEA 200.4%)",
        img: project10_3,
      }
    ],
  },
  {
    id: 11,
    projectName: "Welfare Department",
    mainProject: Project11,
    projectDetails: [
      "Welfare Department - Nesher",
      "A community center providing social services and community activities for the residents of Tur&#39;an",
    ],
    imgBackground: [
      {
        background:
          "linear-gradient(180deg, #B5E0FF 0%, #B0C1DD 195.09%, #F3EFEA 200.4%)",
        img: project11_1,
      },
      {
        background:
          "linear-gradient(180deg, #578BBA 0%, rgba(176, 193, 221, 0.60) 181.46%, #F3EFEA 200.4%)",
        img: project11_2,
      },
      {
        background:
          "linear-gradient(180deg, #A5C8FA 0%, #C0C6E2 181.46%, #F3EFEA 200.4%)",
        img: project11_3,
      },
      {
        background:
          "linear-gradient(180deg, #D7C6C6 0%, #D5E5F2 181.46%, #F3EFEA 200.4%)",
        img: project11_4,
      }
    ],
  },
  {
    id: 12,
    projectName: "Zidan Tower",
    mainProject: Project12,
    projectDetails: [
      "Zaidon Tower, Nazareth. Two residential buildings, each with 10 floors and two underground parking levels.",
      "The project includes 56 residential units with apartments of 4 and 5 rooms and penthouses. <br/><span style='font-weight: 700;'>Developer :</span> Toufik Zaidan Ltd <br/><span style='font-weight: 700;'>Architect :</span> Maamoun Hassan",
    ],
    imgBackground: [
      {
        background:
          "linear-gradient(180deg, #B5E0FF 0%, #B0C1DD 195.09%, #F3EFEA 200.4%)",
        img: project12_1,
      },
      {
        background:
          "linear-gradient(180deg, #578BBA 0%, rgba(176, 193, 221, 0.60) 181.46%, #F3EFEA 200.4%)",
        img: project12_2,
      }
    ],
  },
  {
    id: 13,
    projectName: "Ar&#39;ara Youth Club",
    mainProject: Project13,
    projectDetails: [
      "Ar&#39;ara Youth Club",
      "A public facility designed for recreational, educational, and social activities for the youth inAr&#39;ara. <br/><span style='font-weight: 700;'>Developer :</span> Ar&#39;ara Municipality <br/><span style='font-weight: 700;'>Architect :</span> Mamoun Hasan",
    ],
    imgBackground: [
      {
        background:
          "linear-gradient(180deg, #B5E0FF 0%, #B0C1DD 195.09%, #F3EFEA 200.4%)",
        img: project13_1,
      },
      {
        background:
          "linear-gradient(180deg, #578BBA 0%, rgba(176, 193, 221, 0.60) 181.46%, #F3EFEA 200.4%)",
        img: project13_2,
      },
      {
        background:
          "linear-gradient(180deg, #A5C8FA 0%, #C0C6E2 181.46%, #F3EFEA 200.4%)",
        img: project13_3,
      },
      {
        background:
          "linear-gradient(180deg, #D7C6C6 0%, #D5E5F2 181.46%, #F3EFEA 200.4%)",
        img: project13_4,
      }
    ],
  },
  {
    id: 14,
    projectName: "Ktain Al Shomar",
    mainProject: Project14,
    projectDetails: [
      "Ktain Al Shomer, elementary school in Umm al-Fahm. A project spanning approximately 6,800 square meters. <br/><span style='font-weight: 700;'>Developer :</span> Umm al-Fahm Municipality <br/><span style='font-weight: 700;'>Architect :</span> Mamoun Hasan",
      "",
    ],
    imgBackground: [
      {
        background:
          "linear-gradient(180deg, #B5E0FF 0%, #B0C1DD 195.09%, #F3EFEA 200.4%)",
        img: project14_1,
      },
      {
        background:
          "linear-gradient(180deg, #578BBA 0%, rgba(176, 193, 221, 0.60) 181.46%, #F3EFEA 200.4%)",
        img: project14_2,
      },
      {
        background:
          "linear-gradient(180deg, #A5C8FA 0%, #C0C6E2 181.46%, #F3EFEA 200.4%)",
        img: project14_3,
      },
      {
        background:
          "linear-gradient(180deg, #D7C6C6 0%, #D5E5F2 181.46%, #F3EFEA 200.4%)",
        img: project14_4,
      },
      {
        background:
          "linear-gradient(180deg, #4C88BC 0%, #E3E6EB 198.18%, #F3EFEA 202.62%)",
        img: project14_5,
      }
    ],
  }

];

export const clientSayData = [
  { id: 1, img: client4, name: "ERAN MEBEL" },
  { id: 2, img: client1, name: "TSAMAROT" },
  { id: 3, img: client2, name: "SAMAR AWWAD" },
  { id: 4, img: client3, name: "HASAN ARCHETICS" },
  { id: 5, img: client5, name: "EBRAHIM NASSER" },
  { id: 6, img: client6, name: "A.A ABU RAS" },
];

export const getFormData = (formDataObj) => {
  const formData = new FormData();
  Object.keys(formDataObj).forEach((key) => {
    formData.append(key, formDataObj[key]);
  });
  return formData;
};

export const validateEmail = (email) => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};

export const validateMobile = (mobile) => {
  return mobile.match(/^\d{10}$/);
};
